import React/*, {useState}*/ from 'react';
//import {View} from 'react-native'
//import {Link} from 'react-router-dom';
import {useracc, getApi/*, postApi*/} from '../utils/functions';



//import Moment from 'react-moment';
import {UserContext} from '../utils/usercontext';

import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

//const {hasCommandModifier} = KeyBindingUtil;


//const Index = props => {
class NewShows extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
			newshows: {}
    };
    //this.onEditorStateChange = this.onEditorStateChange.bind(this);
		this.NewShowAdd = this.NewShowAdd.bind(this);
		this.NewShowDelete = this.NewShowDelete.bind(this);
		this.NewShowConfirm = this.NewShowConfirm.bind(this);
  };

  //static contextType = UserContext;
  //loading:any = React.createRef();
  
  async componentDidMount() {
    //static contextType = AppContext;
    const value = this.context;

    //const [context, setContext] = useContext(UserContext);
    const getUser = await useracc();
    console.log( getUser );
    //this.state.user = getUser;
    value.setUser( getUser );

    //console.log(this.context);

    //const [editorState, setEditorState] = useState('');
    const x = await getApi('/api/v1/newshows');
    console.log( x.data );
		this.setState({newshows: x.data.NewShows});



  }



	NewShowAdd = async(e) => {
    this.NewShowConfirm(e, 1);
	}

	NewShowDelete = async(e) => {
    this.NewShowConfirm(e, 0);

	}

	NewShowConfirm = async(id, option) => {
		console.log(option);

		let items = {...this.state.newshows};
		for await ( let x of Object.keys(items) ) {
			//items[x] = eventData[x];
			//if( x > z ) z = x;
			if( items[x].id === id ) {
				items[x].processing = true;
			}
		}
		this.setState({newshows: items});

		await getApi( '/api/v1/newshows/'+id+'/'+option );
    //console.log(x);
		
		items = {...this.state.newshows};
		for await ( let x of Object.keys(items) ) {
			//items[x] = eventData[x];
			//if( x > z ) z = x;
			if( items[x].id === id ) {
				items[x].complete = true;
			}
		}
		this.setState({newshows: items});
		
	}

  render() {
    //const user = this.context;
		const newshows = 
      Object.keys(this.state.newshows).map((e,i) => {
				const words = this.state.newshows[e].air_dates.split(',');
        return (
          <Accordion.Item eventKey={this.state.newshows[e].id}>
						<Accordion.Header><Badge pill bg="secondary">#{this.state.newshows[e].id}</Badge>&nbsp;&nbsp;{this.state.newshows[e].name}&nbsp;&nbsp;{this.state.newshows[e].complete === true &&<span className="text-success">&#x2714;</span>}</Accordion.Header>
						<Accordion.Body>
							<div className="row">
								<div className="col-2">
									<img src={`/assets/poster${this.state.newshows[e].poster}`} width="175" alt="Nothing to show" />
								</div>
								<div className="col-5">
									{this.state.newshows[e].plot}<br /><br />
									{this.state.newshows[e].complete === false &&
									<>
									{this.state.newshows[e].processing === true ?
									<>Processing ...</>
									:
									<>
									<Button onClick={() => this.NewShowAdd(this.state.newshows[e].id)} variant="primary" size="sm">Add</Button>{' '}
									<Button onClick={() => this.NewShowDelete(this.state.newshows[e].id)} variant="danger" size="sm">Delete</Button>
									</>
									}
									</>
									}
								</div>
								<div className="col-2">
									Air Dates:<br />
									{Object.keys(words).map((e,i) => {
										return (
									
											<>{words[e]}<br /></>
										)
									})}
								</div>
								<div className="col-3">
									Network: {this.state.newshows[e].networks}<br />
									Genres: {this.state.newshows[e].genres}<br />
									Season: {this.state.newshows[e].season}
								</div>
							</div>
						</Accordion.Body>
					</Accordion.Item>
				)
			})

    return (
      <>
      
     
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
                                        
            
						<Accordion defaultActiveKey="0">
							{newshows}
						</Accordion>
          
          
            
          </div>
        </div>
      </div>
     
    	</>
    );
  }
}

export default NewShows;
  