import axios from 'axios';


//import util from 'util';
//import crypto from 'crypto';
//import qrcode from 'qrcode';


export async function useracc ( redirect = 0 ) {
	return new Promise((resolve, reject) => {
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				//authorization: 'Bearer ' + localStorage.getItem("authorization"),
				token: 'token ' + localStorage.getItem("token"),
			},
			withCredentials: true
		};
		axios.get('https://jb007.ssmwsab.rocks/api/v1/account', config, { withCredentials: true } )
		.then(res => {
			
			//global.userconfig = res.data
			//this.hideComponent("showHeader");
			//console.log( res );

			//if( res.data.token.length ) {
				//console.log( res.data.token );
				//localStorage.setItem("authorization", res.data.token);
			//}

			resolve (res.data);
		})
		.catch(error => {
			//window.localStorage.clear();
			if( redirect === 0 ) window.location.href = "/login";
			resolve( error);
		});
	});
}

export async function userPerms(uid, user, admin = 0) {
	return new Promise((resolve, reject) => {
		if( user.admin ) {
			if( user.admin.admin_access === 1 && admin === 1 ) resolve(true);
			if( user.admin.mod_access === 1 ) resolve(true);
		}
		if( user.user.uid === uid ) resolve(true);
		return resolve(false);
	});
}

export async function getApi( url ) {
	return new Promise(async (resolve, reject) => {
		//if( localStorage.getItem("authorization") ) await token();
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				//authorization: 'Bearer ' + localStorage.getItem("authorization"),
				token: 'token ' + localStorage.getItem("token"),
			},
			withCredentials: true
		};
		axios.get(url, config )
		.then(res => {
			
			
			resolve (res);
		})
		.catch(error => {
			if (error.message === "Network Error"){
				
				setTimeout(_ => {
        	resolve(getApi(url));
      	}, 5000);
			} else {
				resolve(error);
			}
		});
	});

}

export async function postApi( url, params ) {
	return new Promise(async (resolve, reject) => {
		//if( localStorage.getItem("authorization") ) await token();
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				//authorization: 'Bearer ' + localStorage.getItem("authorization"),
				token: 'token ' + localStorage.getItem("token"),
			},
			withCredentials: true
		};
		axios.post(url, params, config )
		.then(res => {
			
			resolve (res);
		})
		.catch(error => {
			if (error.message === "Network Error"){
				
				setTimeout(_ => {
        	resolve(postApi(url,params));
      	}, 5000);
			} else {
				resolve(error);
			}
		});
	});
}

export async function putApi( url, params ) {
	//console.log(params);
	return new Promise(async (resolve, reject) => {
		//if( localStorage.getItem("authorization") ) await token();
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				//authorization: 'Bearer ' + localStorage.getItem("authorization"),
				token: 'token ' + localStorage.getItem("token"),
			},
			withCredentials: true
		};
		axios.put(url, params, config )
		.then(res => {
			
			resolve (res);
		})
		.catch(error => {
			if (error.message === "Network Error"){
				
				setTimeout(_ => {
        	resolve(putApi(url,params));
      	}, 5000);
			} else {
				resolve(error);
			}
		});
	});
}

export async function deleteApi( url ) {
	return new Promise(async (resolve, reject) => {
		//if( localStorage.getItem("authorization") ) await token();
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				//authorization: 'Bearer ' + localStorage.getItem("authorization"),
				token: 'token ' + localStorage.getItem("token"),
			},
			withCredentials: true
		};
		axios.delete(url, config )
		.then(res => {
			
			resolve (res);
		})
		.catch(error => {
			if (error.message === "Network Error"){
				
				setTimeout(_ => {
        	resolve(deleteApi(url));
      	}, 5000);
			} else {
				resolve(error);
			}
		});
	});
}

/*async function token() {
	while(!localStorage.getItem("token" )) {
		await new Promise(r => setTimeout(r, 250));
	}
}*/


